import { generateDeviceType } from "../../helpers/data_management";
import api from "../../utils/api";
import * as types from "../types/job_type";

const jobAttributes = `
  id
  title
  image
  image_thumb
  image_mobile
  image_position
  image_size
  job_type
  tracks {
    id
    title
  }
  track_id
  state_region
  location
  description
  short_description
  requirements
  bookmark
  boosted
  have_applied
  have_rejected
  created_at
  salary
  expired
  slug
  active    
  external_job_url
  secondary_company_id
  active_at
  company {
    id
    name
    register_name
    type_id
    industry
    short_description
    cover_image
    cover_image_thumb
    job_count
    bookmark
    logo
    slug
  }
`;

const jobAttributesV1 = `
  id
  title
  image
  spotlight
  keywordHighlight
  keywordHighlightText
  imageThumb
  imageMobile
  imagePosition
  imageSize
  tracks{
    id
    title
    titleBm
  }
  salaryCategory {
    id
    title
    titleBm
  }
  jobType {
    id
    title
    titleBm
  }
  trackId
  stateRegion
  location
  description
  shortDescription
  requirements
  bookmark
  boosted
  haveApplied
  haveRejected
  createdAt
  salary
  expired
  slug
  active    
  scraped
  externalJobUrl
  secondaryCompanyId
  activeAt
  company {
    id
    name
    registerName
    typeId
    industry {
      id
      title
      titleBm
    }
    shortDescription
    coverImage
    coverImageThumb
    jobCount
    bookmark
    logo
    slug
    lastActiveAt
  }
`;

const jobAttributesWithCompanyAndProfileV1 = `
  id
  title
  skills
  minYearsExperience
  maxYearsExperience
  image
  imageThumb
  imageMobile
  imagePosition
  imageSize
  jobType {
    id
    title
    titleBm
  }
  salaryCategory {
    id
    title
    titleBm
  }
  tracks {
    id
    title
    titleBm
  }
  trackId
  stateRegion
  location
  description
  shortDescription
  requirements
  bookmark
  haveApplied
  haveRejected
  createdAt
  salary
  expired
  boosted
  slug
  active
  activeAt
  scraped
  externalJobUrl
  secondaryCompanyId
  spotlight
  boosted
  keywordHighlight
  structuredJobData
  company {
    id
    name
    registerName
    typeId
    industry {
      id
      title
      titleBm
    }
    logo
    shortDescription
    coverImage
    coverImageThumb
    jobCount
    bookmark
    slug
    lastActiveAt
    benefits {
      title
      description
      iconCode
    }
    profile {
      address
      latitude
      longitude
      descriptions {
        title
        body
      }
      images {
        cover
        image
      }
      videos {
        title
        category
        vimeoId
        quote
        personName
        personPosition
      }
      extras {
        title
        vimeoId
      }
    }
    sizeId
  }
`;

// For Filter Params Mutation
const updateJobsParams =
  (params = {}) =>
  async (dispatch, getState) => {
    return dispatch({
      type: types.UPDATE_JOB_PARAMS,
      payload: params,
    });
  };

const clearFilters = () => async (dispatch, getState) => {
  return dispatch({
    type: types.CLEAR_FILTERS,
  });
};

const updateFilterApplied = (params) => async (dispatch, getState) => {
  return dispatch({
    type: types.UPDATE_APPLIED_FILTER,
    payload: params,
  });
};

// For Filters Suggestion
const getTracks =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
        tracks {
          id
          title
          titleBm
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const tracks = response.data.data.tracks;
          return dispatch({
            type: types.GET_TRACK_SUCCEEDED,
            payload: tracks,
          });
        }
      });
  };

const getStateRegions =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
      stateRegions {
          id
          state
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const stateRegions = response.data.data.stateRegions;
          return dispatch({
            type: types.GET_STATEREGION_SUCCEEDED,
            payload: stateRegions,
          });
        } else {
          return dispatch({
            type: types.GET_STATEREGION_FAILED,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: types.GET_STATEREGION_FAILED,
        });
      });
  };

const getJobTypes =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
      jobTypes {
          id
          title
          titleBm
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const jobTypes = response.data.data.jobTypes;
          return dispatch({
            type: types.GET_JOB_TYPE_SUCCEEDED,
            payload: jobTypes,
          });
        }
      });
  };

const getExperienceLevels =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
      experiences {
          id
          title
          titleBm
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const experienceLevels = response.data.data.experiences;
          return dispatch({
            type: types.GET_EXPERIENCE_LEVEL_SUCCEEDED,
            payload: experienceLevels,
          });
        }
      });
  };

// For preventing double API call
const updateLoadJobKey = (key) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_LOAD_JOB_KEY,
        payload: key,
      })
    );
  });
};

// Job List
const getJobs =
  (params = {}, pagination = {}, next, prev) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_ALL_JOBS, payload: true });

    let keywordInput = params.keyword ? `keyword: "${params.keyword}",` : "";

    let trackInput = params.trackIds
      ? `trackIds: ${JSON.stringify(params.trackIds)},`
      : "";

    let stateRegionInput = params.stateRegions
      ? `stateRegions: ${JSON.stringify(params.stateRegions)},`
      : "";

    let jobTypeInput = params.jobTypeIds
      ? `jobTypeIds: ${JSON.stringify(params.jobTypeIds)},`
      : "";

    let experienceInput = params.experienceIds
      ? `experienceIds: ${JSON.stringify(params.experienceIds)},`
      : "";

    let expectedSalaryInput = `expectedSalary: ${
      params.expectedSalary ? params.expectedSalary : 0
    },`;

    let salaryCategoryInput = `salaryCategory: "${
      params.type ? params.type : ""
    }",`;

    let companyId = params.companyIds
      ? `companyIds: ${JSON.stringify(params.companyIds)},`
      : "";

    let queryInput =
      keywordInput +
      trackInput +
      stateRegionInput +
      jobTypeInput +
      experienceInput +
      companyId +
      expectedSalaryInput +
      salaryCategoryInput;

    //Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    /**
     * PAGINATION BEHAVIOUR
     *
     * Scenario 1 - going to NEXT page
     * {last: null, first: null, before: "null", after: "endCursor" }
     *
     * Scenario 2 - going to PREVIOUS page
     * {last: 30, first: null, before: "startCursor", after: "" }
     *
     * NOTE: after needs to be an empty string when going to previous page for it to work properly
     */

    const localEndCursor =
      pagination.endCursor === null ? "" : pagination.endCursor;
    const localStartCursor =
      pagination.startCursor === null ? "" : pagination.startCursor;

    const payload = {
      query: `{
        jobListsSearchResults(${queryInput}, last: ${pagination.last}, first: ${pagination.first}, before: "${localStartCursor}", after: "${localEndCursor}") {
          totalCount
          totalPage
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            ${jobAttributesV1}
          }
        }
      }
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data?.jobListsSearchResults) {
            const { jobListsSearchResults } = response.data.data;

            return dispatch({
              type: types.FETCH_JOBS_SUCCEED,
              payload: {
                jobList: jobListsSearchResults.nodes,
                totalJobsPages: jobListsSearchResults.totalPage,
                totalJobsCount: jobListsSearchResults.totalCount,
                pageInfo: jobListsSearchResults.pageInfo,
                jobCurrentPage: next ? 1 : prev ? -1 : 0,
                loadJobKey: params.loadJobKey,
              },
            });
          }
        }
        // return dispatch({ type: types.FETCHING_ALL_JOBS, payload: false });
      });
    // .catch((error) => {
    //   return dispatch({
    //     type: types.FETCH_JOBS_FAILED,
    //     currentLoadingJobsKey: params.currentLoadingJobsKey,
    //   });
    // });
  };

// const applyJob =
//   (params = {}) =>
//   (dispatch, getState) => {
//     dispatch({ type: types.APPLY_JOB });

//     const deviceType = generateDeviceType();

//     const payload = {
//       query: `mutation {
//       applyJob(input: {
//         jobId: "${params}",
//         deviceType: "${deviceType}"
//         }) {
//         applyJob {
//           id
//           job {
//             ${jobAttributesV1}
//           }
//         }
//       }
//     }
//   `,
//     };

//     return api
//       .apiCall("/api/job_seeker/v1/graphql", params, payload)
//       .then(async (response) => {
//         if (response.status === 200) {
//           const { errors } = response.data;
//           if (errors) {
//             return dispatch({
//               type: types.APPLY_JOB_FAILED,
//               payload: { message: errors[0].message },
//             });
//           }

//           if (response.data.data.applyJob != null) {
//             let cJob;
//             let appliedJob = response.data.data.applyJob.applyJob;
//             if (appliedJob && appliedJob.job) {
//               cJob = appliedJob.job;
//             }

//             return dispatch({
//               type: types.APPLY_JOB_SUCCEED,
//               payload: {
//                 appliedJob: cJob,
//                 updateList: params.updateList,
//                 updateRejectedList: params.updateRejectedList,
//                 updateRecommendedList: params.updateRecommendedList,
//                 jobApplication: appliedJob,
//                 triggerNPS: response.data.data.applyJob.triggerNPS, //Not sure whether we need for Web at the moment
//               },
//             });
//           }
//         }
//         return dispatch({
//           type: types.APPLY_JOB_FAILED,
//           payload: { message: "Sorry there was a problem with your request" },
//         });
//       })
//       .catch((error) => {
//         return dispatch({
//           type: types.APPLY_JOB_FAILED,
//           payload: { message: "Sorry there was a problem with your request" },
//         });
//       });
//   };

const getTrendingJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.GET_TRENDING_JOBS });

    const payload = {
      query: `{
        homepageTrendingJobs {
          maxPageSize
          totalCount
          totalPage
          edges {
              node{
                  job {
                    ${jobAttributesV1}
                  }
              }
          }
        }
      }
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { homepageTrendingJobs } = response?.data?.data || {};
          const { edges: _trendingJobArr } = homepageTrendingJobs || {};
          const trendingJobs = Array.isArray(_trendingJobArr)
            ? _trendingJobArr.map((edge) => edge.node.job)
            : [];
          if (!trendingJobs) {
            return dispatch({
              type: types.GET_TRENDING_JOBS_FAILED,
            });
          }
          return dispatch({
            type: types.GET_TRENDING_JOBS_SUCCEED,
            payload: {
              trendingJobs: trendingJobs,
            },
          });
        }
        return dispatch({ type: types.GET_TRENDING_JOBS_FAILED });
      })
      .catch((err) => dispatch({ type: types.GET_TRENDING_JOBS_FAILED }));
  };

// For Single Job Page
const getJob =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.GET_JOB });

    const payload = {
      query: `{
      job(id: "${params.jobId}"){
        ${jobAttributesWithCompanyAndProfileV1}
      }
    }
  `,
    };

    return api
      .interceptApiCall("/api/job_seeker/v1/graphql", params, payload, true)
      .then(async (response) => {
        if (response.status === 200) {
          const { job } = response.data.data;

          if (job != null) {
            return dispatch({
              type: types.GET_JOB_SUCCEED,
              job: job,
              jobId: params.job_id,
            });
          }
          if (job === null) {
            return dispatch({
              type: types.GET_JOB_FAILED,
              job: job,
              jobId: params.job_id,
              jobNotFound: true,
            });
          }
        }
        return dispatch({ type: types.GET_JOB_FAILED, jobId: params.job_id });
      });
  };

const getJobCompanyJobs =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.GET_JOB_COMPANY_JOBS });

    const payload = {
      query: `{
      companyJobLists(company_id: "${params.companyId}") {
        ${jobAttributes}
        }
      }    
    `,
    };

    return api
      .apiCall("/api/v2/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { companyJobLists } = response?.data?.data;

          if (companyJobLists != null) {
            return dispatch({
              type: types.GET_JOB_COMPANY_JOB_SUCCEED,
              payload: { companyJobs: companyJobLists },
            });
          }
          if (companyJobLists === null) {
            return dispatch({ type: types.GET_JOB_COMPANY_JOB_FAILED });
          }
        }
        return { type: types.GET_JOB_COMPANY_JOB_FAILED };
      });
  };

const getJobWithoutRedux = async (params = {}) => {
  const payload = {
    query: `
    {
      job(id: "${params.jobId}") {
        structuredJobData
        id
        title
        active
        externalJobUrl
        scraped
        slug
        image
        imageThumb
        haveApplied
        stateRegion
        salary
        salaryCategory {
          title
          titleBm
        }
        jobType {
          title
          titleBm
        }
        tracks {
          title
          titleBm
        }
        description
        requirements
        company {
          id
          name
          logo
          slug
          profile {
            images {
              image
            }
            descriptions {
              body
            }
          }
          benefits {
            id
            iconCode
            title
            description
          }
          industry {
            title
            titleBm
          }
        }
      }
    }
    `,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload,
    true
  );

  if (response.status === 200) {
    const { job } = response?.data?.data;

    if (job != null) {
      return job;
    }
    if (job === null) {
      return { jobNotFound: true };
    }
  }
  return await { jobNotFound: true };
};

const getAppliedJobs =
  (params = {}) =>
  async (dispatch) => {
    const localEndCursor = params.endCursor === null ? "" : params.endCursor;
    const localStartCursor =
      params.startCursor === null ? "" : params.startCursor;

    const payload = {
      query: `
      {
        appliedJobs(first: ${params.first}, last: ${params.last}, before: "${localStartCursor}", after: "${localEndCursor}") {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            id
            applicationStatus
            status
            createdAt
            job {
              id
              title
              image
              spotlight
              keywordHighlight
              keywordHighlightText
              imageThumb
              imageMobile
              imagePosition
              imageSize
              salaryCategory {
                id
                title
                titleBm
              }
              jobType {
                id
                title
                titleBm
              }
              tracks {
                id
                title
                titleBm
              }
              trackId
              stateRegion
              location
              description
              shortDescription
              requirements
              bookmark
              boosted
              haveApplied
              haveRejected
              createdAt
              salary
              expired
              slug
              active
              scraped
              externalJobUrl
              secondaryCompanyId
              activeAt
              company {
                id
                name
                registerName
                typeId
                industry {
                  id
                  title
                  titleBm
                }
                shortDescription
                coverImage
                coverImageThumb
                jobCount
                bookmark
                logo
                slug
                lastActiveAt
              }
            }
          }
        }
      }    
    `,
    };

    const appliedJobsRes = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (appliedJobsRes.status === 200) {
      const { errors } = appliedJobsRes?.data;
      const { appliedJobs } = appliedJobsRes?.data?.data;
      const { nodes, pageInfo: _pageInfo } = appliedJobs;

      if (Array.isArray(errors) && errors.length > 0 && errors[0]) {
        return dispatch({
          type: types.GET_APPLIED_JOBS_FAILED,
          message: errors[0].toString(),
        });
      }

      return dispatch({
        type: types.GET_APPLIED_JOBS_SUCCESS,
        payload: {
          appliedJobs: nodes,
          pageInfo: _pageInfo,
        },
      });
    }
    return dispatch({
      type: types.GET_APPLIED_JOBS_FAILED,
    });
  };

const getPendingJobs =
  (params = {}) =>
  async (dispatch) => {
    const localEndCursor = params.endCursor === null ? "" : params.endCursor;
    const localStartCursor =
      params.startCursor === null ? "" : params.startCursor;

    const payload = {
      query: `
        {
          pendingJobs(first: ${params.first}, last: ${params.last}, before: "${localStartCursor}", after: "${localEndCursor}") {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            nodes {
              id
              createdAt
              job {
                id
                title
                image
                spotlight
                keywordHighlight
                keywordHighlightText
                imageThumb
                imageMobile
                imagePosition
                imageSize
                jobType
                tracks {
                  id
                  title
                }
                trackId
                stateRegion
                location
                description
                shortDescription
                requirements
                bookmark
                boosted
                haveApplied
                haveRejected
                createdAt
                salary
                expired
                slug
                active
                scraped
                externalJobUrl
                secondaryCompanyId
                activeAt
                company {
                  id
                  name
                  registerName
                  typeId
                  industry
                  shortDescription
                  coverImage
                  coverImageThumb
                  jobCount
                  bookmark
                  logo
                  slug
                  lastActiveAt
                }
              }
            }
          }
        }      
      `,
    };

    const pendingJobRes = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (pendingJobRes.status === 200) {
      const { errors } = pendingJobRes?.data;
      const { nodes, pageInfo: _pageInfo } =
        pendingJobRes?.data.data?.pendingJobs;

      if (Array.isArray(errors) && errors.length > 0 && errors[0]) {
        return dispatch({
          type: types.GET_PENDING_JOBS_FAILED,
          message: errors[0].toString(),
        });
      }

      return dispatch({
        type: types.GET_PENDING_JOBS_SUCCESS,
        payload: {
          pendingJobs: nodes,
          pageInfo: _pageInfo,
        },
      });
    }
    return dispatch({
      type: types.GET_PENDING_JOBS_FAILED,
    });
  };

const cancelJobApplication =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
        mutation {
          unApplyJob(input: {id: "${params.id}"}) {
            unApplyJob {
              id
              job {
                id
              }
            }
          }
        }      
      `,
    };

    const response = await api.apiCall("/api/v2/graphql", params, payload);

    if (response.status === 200) {
      const { errors } = response?.data;
      const { unApplyJob } = response?.data.data?.unApplyJob || {};

      if (Array.isArray(errors) && errors.length > 0 && errors[0]) {
        return dispatch({
          type: types.CANCEL_JOB_APPLICATION_FAILED,
          message: errors[0].toString(),
        });
      }

      return dispatch({
        type: types.CANCEL_JOB_APPLICATION_SUCCESS,
        payload: {
          cancelledJobId: params.id,
        },
      });
    }
    return dispatch({
      type: types.CANCEL_JOB_APPLICATION_FAILED,
    });
  };

const applyJob =
  (params = {}) =>
  async (dispatch) => {
    const deviceType = generateDeviceType();

    const payload = {
      query: `
        mutation {
          applyJob(input: {jobId: "${params.jobId}", deviceType: "${deviceType}"}) {
            success
            applyJob
          }
        }
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status === 200) {
      const { errors } = response?.data;

      if (Array.isArray(errors) && errors.length > 0) {
        if (
          typeof errors[0].message === "string" &&
          errors[0]?.message?.toLowerCase().includes("login")
        ) {
          return dispatch({
            type: types.LOGIN_REQUIRED_TO_APPLY,
            errMsg: errors[0].message,
          });
        }

        return dispatch({
          type: types.APPLY_JOB_FAILED,
          errMsg: errors[0].message,
        });
      }

      const { applyJob } = response?.data?.data;
      const { success } = applyJob;

      if (success === true) {
        return dispatch({
          type: types.APPLY_JOB_SUCCEED,
          jobId: params.jobId,
        });
      }
    }

    return dispatch({
      type: types.APPLY_JOB_FAILED,
    });
  };

const getJobAppliedAndBookmarkStatus =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `
        {
          job(id: "${params.jobId}") {
            bookmark
            haveApplied
          }
        }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.job) {
            const { bookmark, haveApplied } = response.data.data.job;

            return dispatch({
              type: types.GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED,
              bookmark,
              haveApplied,
            });
          }
        }

        return dispatch({
          type: types.GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED,
        });
      });
  };

const getSalaryType =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `
        {
          salaryCategory {
            id
            title
            titleBm
          }
        }    
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data) {
            const { salaryCategory } = response.data?.data;

            return dispatch({
              type: types.GET_SALARY_TYPE_SUCCESS,
              payload: {
                salaryTypes: salaryCategory,
              },
            });
          }
        }

        return dispatch({
          type: types.GET_SALARY_TYPE_FAILED,
        });
      });
  };

const getHomepageBanners =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `{
        homepageSliders {
          edges {
            node {
              image
              urlLink
              order
            }
          }
        }
      }  
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { edges } = response?.data?.data?.homepageSliders;

          // Randomise each image upon request
          const randomIndex = Math.floor(Math.random() * edges.length);

          return dispatch({
            type: types.FETCH_HOMEPAGE_BANNERS,
            payload: edges[randomIndex],
          });
        }
      });
  };

export {
  applyJob,
  cancelJobApplication,
  clearFilters,
  getAppliedJobs,
  getExperienceLevels,
  getHomepageBanners,
  getJob,
  getJobAppliedAndBookmarkStatus,
  getJobCompanyJobs,
  getJobTypes,
  getJobWithoutRedux,
  getJobs,
  getPendingJobs,
  getSalaryType,
  getStateRegions,
  getTracks,
  getTrendingJobs,
  updateFilterApplied,
  updateJobsParams,
  updateLoadJobKey,
};
